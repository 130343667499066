

export default {
  name: "welcome",
  components: {},
  methods: {
    login: function () {
      this.$store.state.msalObj.loginRedirect({
        scopes: ["User.Read"]
      });
    }
  }
};
