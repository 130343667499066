
import { mapGetters } from "vuex";
import ManualList from '@/components/ManualList.vue';
import HaloSupportBox from '@/components/HaloSupportBox.vue';

const boxFormat = function (box) {
  if (box && box.Min && box.Max) {
    return `(${box.Min[0]} x ${box.Min[1]}, ${box.Max[0]} x ${box.Max[1]})`;
  } else {
    return "";
  }
};

const compareStudy = function (studyA, studyB) {
  // sort first by SeriesInstanceUID
  if (studyA.SeriesInstanceUID !== studyB.SeriesInstanceUID) {
    const series = [studyA.SeriesInstanceUID, studyB.SeriesInstanceUID];
    series.sort();
    if (series[0] === studyA.SeriesInstanceUID) {
      return -1;
    } else {
      return 1;
    }
  }
  // then sort by StageNumber and then Instance
  const fields = ["StageNumber", "Instance"];
  let a, b;

  for (const field of fields) {
    a = parseInt(studyA[field], 10);
    b = parseInt(studyB[field], 10);
    if (isNaN(a)) a = 0;
    if (isNaN(b)) b = 0;

    if (a !== b) {
      return a - b;
    }
  }

  return a - b;
};

export default {
  name: "manualstudy",
  components: {
    ManualList,
    HaloSupportBox
  },
  data () {
    return {
      showUnselected: true,
      unselectedStudies: [],
      croppedStudies: [],
      imagedStudies: [],
      crops: {},
      base: null,
      peak: null,
      errors: [],
      isHfStudy: false,
      showCommentInput: false,
      onHoldComment: "",
      showCommentView: false,
      showReport: false,
      showSupportBox: false,
      inputPlaceholder: ""
    };
  },
  computed: {
    ...mapGetters({
      getProductID: "getProductID",
      overview: "getOverview",
      getOpenStudy: "getOpenStudy",
      getOpenStudyClips: "getOpenStudyClips",
      getReportingNode: "getReportingNode",
      getEscalationReason: "getEscalationReason",
      getStudyReports: "getStudyReports",
      getUserName: "getUserName",
      isManualStudyFailed: "getIsManualStudyFailed"
    }),
    errorPresent () {
      if (!this.errors) return false;

      if (this.errors.length) {
        return true;
      }
      return false;
    },
    study () {
      const sortedStudy = this.getOpenStudyClips.slice(0);
      sortedStudy.sort(compareStudy);

      let sequenceId = 1;
      const unselectedStudies = this.unselectedStudies || [];
      const croppedStudies = this.croppedStudies || [];
      const imagedStudies = this.imagedStudies || [];

      var last_series_uid = "";
      var last_stage = "";
      const clips = sortedStudy.map(function (clip) {
        const selected = !unselectedStudies.includes(clip.Blobname);
        const sendCrop = croppedStudies.includes(clip.Blobname);
        const showImage = imagedStudies.includes(clip.Blobname);
        const newSeries = last_series_uid !== clip.SeriesInstanceUID;
        last_series_uid = clip.SeriesInstanceUID;
        const newStage = last_stage !== clip.StageNumber;
        last_stage = clip.StageNumber;
        return {
          selected,
          sendCrop,
          showImage,
          newSeries,
          newStage,
          egSequence: selected ? sequenceId++ : "",
          hasCrop: clip.CroppingBoxCoordinates ? "Y" : "N",
          boxOriginal: boxFormat(clip.OriginalBoxCoordinates),
          boxAuto: boxFormat(clip.CroppingBoxCoordinates),
          ...clip
        };
      });

      return clips;
    },
    isEscalated () {
      return this.getOpenStudy.status === "intervene";
    },
    hasReportFromUser () {
      // const operatorName = this.convertUserToOperator();
      let hasReport = false;
      if (this.getStudyReports.length !== 0) {
        // for (const report of this.getStudyReports) {
        //   if (report.operator === operatorName) {
        hasReport = true;
        //     break;
        //   }
        // }

        // for now, any report is fine. TODO: check for user's report
      }
      return hasReport;
    },
    stageName () {
      const stageNames = [];
      for (let index = 0; index < this.study.length; index++) {
        if (!stageNames.includes(this.study[index].StageName) && this.study[index].StageName !== "") {
          stageNames.push(this.study[index].StageName);
        }
      }
      return stageNames;
    },
    commentExpander: function () {
      if (this.showCommentInput) {
        return "⯅";
      }
      return "▼";
    },
    viewCommentExpander: function () {
      if (this.showCommentView) {
        return "⯅ Hide";
      }
      return "▼ Show";
    },
    reportExpander: function () {
      if (this.showReport) {
        return "⯅ Hide";
      }
      return "▼ Show";
    },
    commentPresent: function () {
      if (this.onHoldComment !== "") {
        return true;
      }
      return false;
    },
    escalationReason: function () {
      if (this.getEscalationReason) {
        return this.getEscalationReason;
      }
      return false;
    },
    studyReport: function () {
      if (this.getStudyReports.length !== 0) {
        return this.getStudyReports;
      }
      return false;
    },
    openStudy: function () {
      if (this.getOpenStudy) {
        return this.getOpenStudy;
      }
      return false;
    }
  },
  methods: {
    checkInputError (stagename) {
      this.errors = [];
      if (stagename === "base") {
        if (this.base === this.peak && this.base !== null) {
          this.errors.push("Base stage cannot be the same as Peak stage.");
        }
      }
      if (stagename === "peak") {
        if (this.peak === this.base && this.peak !== null) {
          this.errors.push("Peak stage cannot be the same as Base stage.");
        }
      }
    },
    isObjectEmpty (obj) {
      return Object.keys(obj).length === 0;
    },
    selectBase () {
      this.checkInputError("base");
    },
    selectPeak () {
      this.checkInputError("peak");
    },
    toggleUnselected () {
      this.showUnselected = !this.showUnselected;
    },
    selectClip (clip) {
      this.listToggle(clip, this.unselectedStudies);
    },
    listToggle (clip, list) {
      const blobIdx = list.indexOf(clip.Blobname);
      if (blobIdx > -1) {
        list.splice(blobIdx, 1);
      } else {
        list.push(clip.Blobname);
      }
    },
    toggleSeriesState (series) {
      this.getOpenStudyClips.map((clip) => {
        if (clip.SeriesInstanceUID === series) {
          this.selectClip(clip);
        }
      });
    },
    toggleStageState (stage) {
      this.getOpenStudyClips.map((clip) => {
        if (clip.StageName === stage) {
          this.selectClip(clip);
        }
      });
    },
    toggleHFClips () {
      this.getOpenStudyClips.map((clip) => {
        if (!clip.MeetsHF) {
          this.selectClip(clip);
        }
      });
    },
    selectAll () {
      this.unselectedStudies = [];
    },
    selectNone () {
      this.unselectedStudies = this.getOpenStudyClips.map(
        (clip) => clip.Blobname
      );
    },
    returnToList () {
      if (this.isEscalated) {
        this.$router.push({
          name: "escalatedStudies",
          params: { service_id: this.$store.state.service }
        });
      } else {
        this.$router.push({
          name: "studies",
          params: { service_id: this.$store.state.service, status: "pending" }
        });
      }
    },
    toggleShowSupportBox () {
      this.showSupportBox = !this.showSupportBox;
      if (this.getOpenStudy.accession) {
        this.inputPlaceholder = `Please enter support info for ${this.getOpenStudy.accession}`;
      }
    },
    async completeReport () {
      if (this.getOpenStudy) {
        this.getStudyReports = await this.$store.dispatch("retrieveReports", { accession: this.getOpenStudy.accession });
      }

      if (!this.hasReportFromUser) {
        this.$store.dispatch("setFlash", { message: 'No report received yet - ensure it is completed in EchoGo to mark this study as done ; re-report if necessary.', status: "error" });
      } else {
        this.$store.dispatch("completeReport", {
          accession: this.getOpenStudy.accession,
          status: "done"
        });
        this.returnToList();
      }
    },
    putStudyOnHold () {
      this.$store.dispatch("completeReport", {
        accession: this.getOpenStudy.accession,
        status: "intervene",
        escalatedReasonText: this.onHoldComment,
        escalatedBy: this.getUserName,
        escalationDatetime: new Date().toISOString()
      });
      this.returnToList();
    },
    resend () {
      const crops = this.crops;
      const croppedStudies = this.croppedStudies;
      const order = {
        label_baseline: this.base,
        label_peak: this.peak
      };
      Object.assign(order, this.getOpenStudy);
      this.$store.dispatch("resendManual", {
        study: order,
        clips: this.study
          .filter((clip) => clip.selected)
          .map(function (clip) {
            const sendCrop = croppedStudies.includes(clip.Blobname);
            return {
              Blobname: clip.Blobname,
              crop: sendCrop ? crops[clip.Blobname] : null
            };
          })
      });
    },
    toggleHoldCommentInput () {
      this.showCommentInput = !this.showCommentInput;
    },
    toggleHoldCommentView () {
      if (this.showReport) {
        this.showReport = false;
      }
      this.showCommentView = !this.showCommentView;
    },
    toggleReport () {
      if (this.showCommentView) {
        this.showCommentView = false;
      }
      this.showReport = !this.showReport;
    },
    convertUserToOperator () {
      const userForename = this.getUserName.split(" ")[0];
      const userLastname = this.getUserName.split(" ")[1];
      const operatorName = userForename + userLastname;
      return operatorName;
    }
  },
  watch: {
    getOpenStudy: {
      deep: true,
      handler () {
        if (this.getHFWorklists.includes(this.getOpenStudy.product_id)) {
          this.isHfStudy = true;
          if (this.unselectedStudies.length === 0) {
            this.toggleHFClips();
          }
        }
      }
    }
  }
};
