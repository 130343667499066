
import StudyListItem from "@/components/StudyListItem.vue";
import { mapGetters } from "vuex";
import { isObjectEmpty } from '../util/index';

export default {
  data () {
    return {
      search: "",
      showSupportBox: false,
      studyForSupport: null,
      inputPlaceholder: ""
    };
  },
  computed: {
    ...mapGetters({
      getLoading: "getLoading",
      apiError: "getAPIError",
      config: "getConfig",
      isSupportGroupMember: "getIsSupportGroupMember",
      userEmail: "getUserEmail"
    }),
    studyList () {
      const list = [...this.studies];
      const selected = this.selected ? this.selected.accession : "";
      for (const study of list) {
        study.selected = study.accession === selected;
      }
      return list;
    },
    filteredList () {
      return this.studies.filter((study) => {
        return study.contract.toLowerCase().includes(this.search.toLowerCase()) ||
          study.accession.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    noStudiesAvailable () {
      if (!this.getLoading && !this.studies.length && isObjectEmpty(this.apiError)) {
        return true;
      }
      return false;
    }
  },
  props: {
    studies: Array,
    selected: Object,
    allowselect: Boolean,
    isEscalated: Boolean
  },
  components: {
    StudyListItem
  },
  methods: {
    toggleShowSupportBox (study) {
      this.showSupportBox = !this.showSupportBox;
      this.studyForSupport = study;
      if (study.accession) {
        this.inputPlaceholder = `Please enter support info for ${study.accession}`;
      }
    },
    chooseStudy (study) {
      this.$emit("select-study", study);
    },
    changeSeachString (searchString) {
      this.$emit("change-search-string", searchString);
    }
  },
  updated () {
    // assign focus to Halo Sign in button/input box to move it into view
    const haloInput = document.getElementById("support-input-container") as HTMLElement;
    if (this.showSupportBox && haloInput) {
      haloInput.focus();
    }
  }
};
