import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import AsyncComputed from 'vue-async-computed';
import env from './env.json';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import * as msal from "@azure/msal-browser";
import HaloSupportBox from "@/components/HaloSupportBox.vue";

Vue.use(AsyncComputed);
Vue.use(BootstrapVue);
Vue.use(require('vue-moment')); // eslint-disable-line

const dev_branch = () => {
  if (Object.prototype.hasOwnProperty.call(env, "DEV_BRANCH")) {
    // eslint-disable-next-line
    return env['DEV_BRANCH'];
  }
};

const conf = {
  config: {
    version: env.VERSION,
    dev_branch: dev_branch,
    order_api: env.VUE_APP_ORDER_API,
    order_api_vna: env.VUE_APP_VNA_API,
    order_api_vna_uk: env.VUE_APP_VNA_API_UK,
    vdi_service_api: env.VUE_APP_VDI_SERVICE_API,
    data_service_api: env.VUE_APP_DATA_SERVICE_API,
    halo_api: env.VUE_APP_HALO_API,
    halo_client_id: env.VUE_APP_HALO_CLIENT_ID
  }
};

store.dispatch("setConfig", conf);

const msalConfig = {
  auth: {
    clientId: env.VUE_APP_MSAL_CLIENTID,
    authority: `https://login.microsoftonline.com/${env.VUE_APP_MSAL_TENANTID}`,
    redirectUri: env.VUE_APP_MSAL_REDIRECTURL
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false
  }
};
store.dispatch("setMsalConfig", msalConfig);
const msalObj = new msal.PublicClientApplication(msalConfig);
store.dispatch("setMsalObj", msalObj);

if (!msalObj.getActiveAccount()) {
  store.dispatch("handleRedirect", msalConfig);
} else {
  store.dispatch('initiateLoggedInState');
}

Vue.component('HaloSupportBox', HaloSupportBox);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

Vue.config.productionTip = false;
