
import ScoreboardList from "@/components/ScoreboardList.vue";
import { mapGetters } from "vuex";

export default {
  name: "dashboard",
  data () {
    return {
      search: "",
      filter_us: true,
      filter_uk: false,
      services: {},
      serviceProducts: [],
      softwareProducts: ["EchoGo Pro", "EchoGo Core", "EchoGo Heart Failure"],
      products: {
        "EchoGo Core": true,
        "EchoGo Heart Failure": true,
        "EchoGo Pro": true
      }
    };
  },
  created () {
    this.$store.dispatch("refreshScoreboard");
    this.$store.dispatch("setServiceTypes", this.overview);
    this.setDefaultCheckbox();
    this.serviceProducts = this.retrieveServiceProducts();
  },
  computed: {
    orders () {
      const search = this.search?.toLowerCase() || "";
      var studies = JSON.parse(JSON.stringify(this.scoreboard));
      // score the orders first - are they in progress or needing attention?
      const scoredStudies = this.scoreStudies(studies);

      // sort the orders by score then age
      scoredStudies.sort((a, b) => {
        if (a.score !== b.score) {
          return b.score - a.score;
        }
        const a_created = Date.parse(a.created);
        const b_created = Date.parse(b.created);
        return b_created - a_created;
      });

      // filter studies by their service/product against the values in serviceProducts array e.g Service, Demo etc
      const filteredStudiesByService = scoredStudies.filter(
        (study) => this.serviceProducts.includes(study.service) === true
      );

      const filteredStudies = [];
      if (Object.keys(this.products).length !== 0) {
        // filter studies by their software against the values in softwareProducts array e.g EchoGo Pro, EchoGo Heart Failure etc
        filteredStudiesByService.forEach((study) => {
          study.results.forEach((studyResult) => {
            // add studyResult to array if studyResult.software is included in selectedProduct
            if (this.hasCheckedProduct(studyResult.software) && !filteredStudies.includes(study)) {
              filteredStudies.push(study);
            }
          });
          // Planned, Preflight and Open studies oftentimes have no results/reports
          if (study.results.length === 0) {
            if (this.hasCheckedProduct(study.service) && !filteredStudies.includes(study)) {
              filteredStudies.push(study);
            }
          }
        });
      }

      // apply the filter
      return filteredStudies.filter((study) => {
        let wanted =
          study.customer?.toLowerCase().includes(search) ||
          study.accession?.toLowerCase().includes(search);

        if (study.service.indexOf("UK") > 0 && !this.filter_uk) {
          wanted = false;
        }
        if (study.service.indexOf("UK") === -1 && !this.filter_us) {
          wanted = false;
        }

        return wanted;
      });
    },
    ...mapGetters({
      apiError: "getAPIError",
      scoreboard: "getScoreboard",
      overview: "getOverview",
      serviceTypes: "getServiceTypes"
    })
  },
  methods: {
    scoreStudies (studies: any) {
      studies.forEach((study) => {
        let score = 0;
        if (study.results_sent === 0) {
          score += 5;
        }
        study.results.forEach((report) => {
          score += (report.pending || !report.accepted) ? 5 : 0;
        });
        study.first_result = study.results
          .map((e) => e.sent_dt)
          .find((e) => e !== "");
        study.score = score;
      });
      return studies;
    },
    setDefaultCheckbox () {
      // by default the service checkbox should be ticked if it exists
      const isServicePresent = this.serviceTypes.some(
        (item) => item.readable === "Service"
      );
      if (isServicePresent) {
        this.services = { Service: true };
      }
    },
    removeFalseValues (collection: object) {
      for (const item in collection) {
        if (collection[item] === false) {
          delete collection[item];
        }
      }
    },
    retrieveServiceProducts () {
      // retrieve the product names for all services that are checked
      const serviceProducts = [];
      for (const service in this.services) {
        const serviceObject = this.serviceTypes.filter(
          (serviceType) => serviceType.readable === service
        );
        if (serviceObject[0].services.length > 1) {
          for (let c = 0; c < serviceObject[0].services.length; c++) {
            serviceProducts.push(serviceObject[0].services[c].name);
          }
        } else {
          serviceProducts.push(serviceObject[0].services[0].name);
        }
      }
      return serviceProducts;
    },
    hasCheckedProduct (software: string) {
      if (software in this.products) {
        return this.products[software];
      } else {
        return false;
      }
    }
  },
  watch: {
    services: {
      deep: true,
      handler () {
        this.removeFalseValues(this.services);
        this.serviceProducts = this.retrieveServiceProducts();
      }
    },
    products: {
      deep: true,
      handler () {
        this.removeFalseValues(this.products);
      }
    }
  },
  components: {
    ScoreboardList
  }
};
