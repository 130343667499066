import Vue from 'vue';
import VueRouter from 'vue-router';

import Dashboard from '@/views/Dashboard.vue';
import Worklist from '@/views/Worklist.vue';
import EscalationList from '@/views/EscalationList.vue';
import ManualStudy from '@/views/ManualStudy.vue';
import Welcome from '@/views/Welcome.vue';
import vdis from '@/views/VDIs.vue';
import Scoreboard from '@/views/Scoreboard.vue';
import Health from '@/views/Health.vue';

import store from '@/store';
import env from '../env.json';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'welcome',
    component: Welcome,
    beforeEnter: (_to, _from, next) => {
      if (store.state.msalObj.getActiveAccount()) {
        next({ name: 'dashboard' });
      } else {
        store.dispatch("setReady");
        next();
      }
    }
  },
  {
    path: '/worklist/halo_login_redirect',
    name: 'haloLoginRedirect',
    component: Worklist,
    redirect: to => {
      const hash = to.hash;
      const params = new URLSearchParams(hash.replace('#', ''));
      const accessToken = params.get('access_token');
      const accessTokenExpiresIn = parseInt(params.get('expires_in'));
      store.commit('setHaloAccessToken', accessToken);
      store.commit('setHaloAccessTokenExpiry', accessTokenExpiresIn);
      return localStorage.getItem('worklistRedirectUrl');
    }
  },
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: (to, _, next) => {
      store.commit("clearReportingNode");
      next();
    }
  },
  {
    path: '/worklist/:service_id/:status',
    name: 'studies',
    component: Worklist,
    beforeEnter: (to, _, next) => {
      store.commit('setLoading', true);
      store.dispatch('selectWorklist', { service: to.params.service_id, status: to.params.status });
      store.dispatch("setVdis");
      store.dispatch('refreshStudies', { limit: "all" });
      next();
    }
  },
  {
    path: '/escalation/:service_id',
    name: 'escalatedStudies',
    component: EscalationList,
    beforeEnter: (to, _, next) => {
      store.commit('setLoading', true);
      store.dispatch('selectWorklist', { service: to.params.service_id, status: "escalate", limit: "all" });
      store.dispatch("setVdis");
      store.dispatch('refreshStudies', { limit: "all" });
      next();
    }
  },
  {
    path: '/manual/:accession',
    name: 'manualStudy',
    component: ManualStudy,
    beforeEnter: async (to, _, next) => {
      await store.dispatch('initiateLoggedInState');
      store.commit('setLoading', true);
      await store.dispatch('retrieveStudyDetails', { accession: to.params.accession });
      store.dispatch("retrieveReports", { accession: to.params.accession });
      const overview = store.state.overview;
      const serviceID = store.state.product_id;
      const service_object = overview.find(x => x.id === serviceID);

      store.commit("setReportingNode", { node: service_object && { ...service_object.vdi, requirements: service_object.echoGoCoreVersions, service_id: serviceID } });
      await store.dispatch('openStudy', { accession: to.params.accession });
      await store.dispatch("refreshOverview");
      store.commit('setLoading', false);
      next();
    }
  },
  {
    path: '/vdis',
    name: 'vdis',
    component: vdis,
    beforeEnter: (_to, _, next) => {
      if (!store.state.isSupportGroupMember) {
        store.dispatch("raiseMessage", { message: "You do not have the necessary permissions to access this page.", status: "error" });
        next({ name: 'dashboard' });
      } else {
        store.dispatch("setVdis");
        next();
      }
    }
  },
  {
    path: '/scoreboard',
    name: 'scoreboard',
    component: Scoreboard
  },
  {
    path: '/health',
    name: 'health',
    component: Health
  }
];

const router = new VueRouter({
  mode: 'history',
  base: env.BASE_URL,
  routes
});

const unauthenticatedPaths = ['welcome', 'health'];

router.beforeEach(async (to, from, next) => {
  if (!store.state.msalObj.getActiveAccount() && !unauthenticatedPaths.includes(to.name)) {
    next({ name: 'welcome' });
  } else {
    next();
  }
});

export default router;
