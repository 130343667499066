
import ScoreboardListStudy from "@/components/ScoreboardListStudy.vue";

export default {
  data () {
    return {
      search: ""
    };
  },
  computed: {
    studyList () {
      return this.studies;
    }
  },
  props: {
    studies: Array
  },
  components: {
    ScoreboardListStudy
  }
};
