/* eslint-disable dot-notation */
import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios';
import clientService from "@/services";
import graphService from "@/services/graph";
import VNA from "@/services/vna";
import VDIService from "@/services/vdi";
import ScoreboardService from "@/services/scoreboard";
import { generateServiceTypes, selectNextStudy } from "@/util/index";
import router from "@/router";
import env from '../env.json';
import { PublicClientApplication, Configuration } from "@azure/msal-browser";

Vue.prototype.$http = axios;
Vue.use(Vuex);

const vnaService = new VNA();
const vdiService = new VDIService();
const scoreboardService = new ScoreboardService();

export default new Vuex.Store({
  strict: env.NODE_ENV !== "production",
  state: {
    apiError: {},
    loaded: false,
    loading: false,
    config: {},
    clientPrincipal: null,
    ws_connection_string: '',
    user_name: "",
    user_email: "",
    flash_message: "No flash message",
    flash_status: null,
    flash_show: false,
    reporting_node: null,
    worklistRedirectUrl: "",
    haloAccessToken: null,
    haloAccessTokenExpiresIn: 0,
    token: "",
    service: "",
    status: "",
    hfWorklists: [],
    notifications: [],
    overview: [],
    vdis: [],
    studies: [],
    service_types: [],
    selected_service_type: "",
    scoreboard: [],
    inprogress_study: null,
    open_study: null,
    open_study_clips: [],
    escalation_reason: {},
    qcForms: [],
    studyReports: [],
    // reason: {},
    _loading_count: 0,
    msalObj: {} as PublicClientApplication,
    msalConfig: {} as Configuration,
    userAccessToken: "",
    isUserAvailable: false,
    isSupportGroupMember: false,
    isOperatorGroupMember: false,
    isManualStudyFailed: false,
    product_id: null
  },
  getters: {
    getAPIError: state => state.apiError,
    ready: state => state.loaded,
    getNotifications: state => state.notifications,
    getFlash: state => { return { message: state.flash_message, status: state.flash_status, show: state.flash_show } },
    getLoading: state => state.loading,
    getStudies: state => state.studies,
    getOverview: state => state.overview,
    getVdis: state => state.vdis,
    getToken: state => state.token,
    getUserEmail: state => state.user_email,
    getUserName: state => state.user_name,
    getConfig: state => state.config,
    getPubsubConnectionString: state => state.ws_connection_string,
    getReportingNode: state => state.reporting_node,
    getHFWorklists: state => state.hfWorklists,
    getSelectedService: state => state.reporting_node && state.overview.find(s => s.id === state.reporting_node.service_id),
    getInprogressStudy: state => {
      for (const study of state.studies) {
        if (study.status === "inprogress" && state.reporting_node && study.report_ae === state.reporting_node.ae_title) {
          return study;
        }
      }
    },
    getOpenStudy: state => state.open_study,
    getProductID: state => state.product_id,
    getOpenStudyClips: state => state.open_study_clips,
    getScoreboard: state => state.scoreboard,
    getServiceTypes: state => state.service_types,
    getSelectedServiceType: state => state.selected_service_type,
    getStudyReports: state => state.studyReports,
    // getEscalationReason: state => state.reason,
    getReportable: state => state.reporting_node != null && state.reporting_node.status !== 'AVAILABLE',
    is_loading (state) {
      return state._loading_count !== 0;
    },
    getEscalationReason: state => state.escalation_reason,
    getQCForms: state => state.qcForms,
    getMsalObj: state => state.msalObj,
    getIsUserAvailable: state => state.isUserAvailable,
    getIsSupportGroupMember: state => state.isSupportGroupMember,
    getIsOperatorGroupMember: state => state.isOperatorGroupMember,
    getIsManualStudyFailed: state => state.isManualStudyFailed
  },
  mutations: {
    refreshStudyList: (state, studies) => {
      state.studies = studies;
      state.inprogress_study = null;
    },
    refreshOverview: (state, overview) => {
      state.overview = overview;
    },
    setAPIError: (state, data) => {
      state.apiError = {
        message: data.message,
        error: data.error,
        service: data.service
      };
    },
    clearAPIError: (state) => {
      state.apiError = {};
    },
    setHFWorklists: (state, data) => {
      state.hfWorklists = data;
    },
    setWorklist: (state, { service, status }) => {
      state.service = service;
      state.status = status;
      state.selected_service_type = state.overview.find(s => s.id === service)?.for;
    },
    setProductID: (state, product_id) => {
      state.product_id = product_id;
    },
    setFlash: (state, { status, message }) => {
      state.flash_message = message;
      state.flash_status = status;
      state.flash_show = true;
    },
    setNotifications: (state, { notificationArray }) => {
      state.notifications = notificationArray;
    },
    setNotification: (state, { message, status, time, ticketLink }) => {
      if (!state.notifications.length) {
        state.notifications = [{ message, status, time, ticketLink }];
      } else {
        state.notifications.unshift({ message, status, time, ticketLink });
      }
    },
    clearFlash: (state) => {
      state.flash_show = false;
    },
    setReady: (state) => {
      state.loaded = true;
    },
    setLoading: (state, isActive) => {
      state.loading = isActive;
    },
    setConfig: (state, config) => {
      state.config = JSON.parse(JSON.stringify(config));
      clientService.setBaseUrl(config.config.order_api);
      vnaService.setBaseUrl(config.config.order_api_vna);
      vnaService.setBaseUrl(config.config.order_api_vna_uk, "uk");
      vdiService.setBaseUrl(config.config.vdi_service_api);
      scoreboardService.setBaseUrl(config.config.data_service_api);
    },
    setPubsubConnectionStr: (state, connectionStr) => {
      state.ws_connection_string = connectionStr;
    },
    setScoreboardData: (state, data) => {
      state.scoreboard = data;
    },
    setServiceTypes: (state, data) => {
      state.service_types = data;
    },
    setClientPrincipal: (state, clientPrincipal) => {
      state.clientPrincipal = clientPrincipal;
      // Adds clientPrincipal to Order API httpClient as a header to authorise access
      clientService.setClientPrincipal(JSON.stringify(clientPrincipal));
    },
    setInprogressStudy: (state, study) => {
      if (study.status === "open") {
        study.status = "inprogress";
      }
      state.inprogress_study = study;
    },
    setReportingNode: (state, { node }) => {
      if (!node || node.status === 'UNAVAILABLE') {
        state.reporting_node = null;
      } else {
        state.reporting_node = node;
      }
    },
    setWorklistRedirectUrl: async (context, url) => {
      localStorage.setItem('worklistRedirectUrl', url);
    },
    setHaloAccessToken: (state, accessToken) => {
      localStorage.setItem('haloAccessToken', accessToken);
    },
    setHaloAccessTokenExpiry: (state, accessTokenExpiresIn) => {
      const expiryTime = new Date().getTime() + accessTokenExpiresIn * 1000;
      localStorage.setItem('haloAccessTokenExpiryTime', `${expiryTime}`);
    },
    clearReportingNode: (state) => {
      state.reporting_node = null;
    },
    setUserEmail: (state, user_email) => {
      state.user_email = user_email;
    },
    setUserName: (state, user_name) => {
      state.user_name = user_name;
    },
    setOpenStudy: (state, { data }) => {
      state.isManualStudyFailed = false;
      if (data) {
        state.open_study = data.order;
        state.open_study_clips = data.metadata;
      } else {
        state.open_study = {};
        state.open_study_clips = [];
      }
    },
    setOpenStudyFailed: (state) => {
      state.isManualStudyFailed = true;
    },
    setVdis: (state, { vdis }) => {
      state.vdis = vdis;
    },
    START_LOADING (state) {
      state._loading_count++;
    },
    STOP_LOADING (state) {
      state._loading_count--;
    },
    setEscalationReason (state, data) {
      if (data) {
        state.escalation_reason = {
          escalatedReasonText: data.escalatedReasonText,
          escalatedBy: data.escalatedBy,
          escalationDatetime: data.escalationDatetime
        };
      } else {
        state.escalation_reason = {};
      }
    },
    setQCForm (state, data) {
      if (data.qc_forms) {
        // sort QC Form data by date in descending order (newest to oldest)
        const sortedQCForms = [...data.qc_forms].sort((a, b) => {
          const c = new Date(a.RowKey).getTime();
          const d = new Date(b.RowKey).getTime();
          return d - c;
        });
        state.qcForms = sortedQCForms;
      } else {
        state.qcForms = [];
      }
    },
    setStudyReport (state, data) {
      if (data) {
        state.studyReports = data.report;
      } else {
        state.studyReports = [];
      }
    },
    setMsalObj (state, data) {
      state.msalObj = data;
    },
    setMsalConfig (state, config) {
      state.msalConfig = config;
    },
    setUserAccessToken (state, token) {
      state.userAccessToken = token;
    },
    loginRedirect (state) {
      state.msalObj.loginRedirect({
        scopes: ["User.Read"]
      });
    },
    setIsUserAvailable (state, isUserAvailable) {
      state.isUserAvailable = isUserAvailable;
    },
    setIsSupportGroupMember (state, isSupportGroupMember) {
      state.isSupportGroupMember = isSupportGroupMember;
    },
    setIsOperatorGroupMember (state, isOperatorGroupMember) {
      state.isOperatorGroupMember = isOperatorGroupMember;
    }
  },
  actions: {
    loginRedirect: (context) => context.commit('loginRedirect'),
    setMsalObj: (context, msalObj) => context.commit('setMsalObj', msalObj),
    setMsalConfig: (context, msalConfig) => context.commit('setMsalConfig', msalConfig),
    handleRedirect: async (context) => {
      try {
        const redirectResponse = await context.state.msalObj.handleRedirectPromise();
        if (!redirectResponse) return;
        context.state.msalObj.setActiveAccount(redirectResponse.account);
        await context.dispatch("initiateLoggedInState");
        router.push({ name: 'dashboard' });
      } catch (error) {
        console.error(error); // eslint-disable-line
      }
    },
    initiateLoggedInState: async (context) => {
      context.commit('setLoading', true);
      const msalObj = new PublicClientApplication(context.state.msalConfig);
      const tokenRequestObj = {
        scopes: ["User.Read"],
        account: msalObj.getActiveAccount()
      };
      try {
        const authResult = await msalObj.acquireTokenSilent(tokenRequestObj);
        context.commit('setUserAccessToken', authResult.accessToken);
        clientService.setAuthenticationToken(authResult.accessToken);
        graphService.setAuthenticationToken(authResult.accessToken);
        const userGroups = await graphService.getGroupMemberships();
        const supportID = env.VUE_APP_SUPPORT_ID;
        const isMemberOfSupport = userGroups.data?.value.some(x => x.id === supportID);
        if (isMemberOfSupport) {
          context.commit("setIsSupportGroupMember", true);
        }
        const operatorID = env.VUE_APP_WORKFLOW_OPERATORS_ID;
        const isMemberOfOperatorGroup = userGroups.data?.value.some(x => x.id === operatorID);
        if (isMemberOfOperatorGroup) {
          context.commit("setIsOperatorGroupMember", true);
        }
        const expiresOn = authResult.expiresOn?.toString() || "";
        localStorage.setItem("tokenExpiryTime", expiresOn);
        await context.dispatch("setPubsubConnectionStr");
        await context.dispatch("refreshOverview");
        await context.dispatch("refreshStudies", { limit: "all" });
        context.commit("setIsUserAvailable", true);
        context.commit("setUserEmail", msalObj.getActiveAccount().username);
        context.commit("setReady");
      } catch (error) {
          console.warn(error); // eslint-disable-line
      }
      context.commit('setLoading', false);
    },
    setReady: (context) => context.commit("setReady"),
    toggleLoading: (context, isActive) => context.commit('setLoading', isActive),
    setVdis: async (context) => {
      const vdis = await vdiService.getAllVdis();
      context.commit("setVdis", { vdis: vdis });
    },
    setConfig: async (context, { config }) => {
      context.commit("setConfig", { config });
    },
    setPubsubConnectionStr: async (context) => {
      const pubsubConnectionStr = await clientService.getOverviewUpdateConnectionStr();
      context.commit("setPubsubConnectionStr", pubsubConnectionStr);
    },
    setServiceTypes: async (context, overview) => {
      const results = generateServiceTypes(overview);
      context.commit("setServiceTypes", results);
    },
    setHFWorklists: (context, overview) => {
      const hasHfToggleData = overview.data.some(worklist => worklist?.features?.hfToggle);
      if (hasHfToggleData) {
        const hfWorklists = overview.data.filter(worklist => worklist.features.hfToggle === true);
        const hfWorklistProductIds = hfWorklists.map(worklist => worklist.id);
        context.commit("setHFWorklists", hfWorklistProductIds);
      }
    },
    refreshOverview: async (context) => {
      const overview = await clientService.getOverview();
      if (overview.success) {
        context.dispatch("setHFWorklists", overview);
        await context.dispatch("getVdisForOverview", { overview: overview.data });
      } else {
        const apiError = {
          message: "Order API failed to retrieve studies.",
          error: overview.message,
          service: "Order API"
        };
        context.commit("setAPIError", apiError);
      }
    },
    refreshScoreboard: async (context) => {
      const scores = await scoreboardService.getData();
      if (scores.success) {
        context.commit("setScoreboardData", scores.data);
      } else {
        const apiError = {
          message: "Data Exporter API failed to retrieve studies.",
          error: scores.message,
          service: "Data Exporter API"
        };
        context.commit("setAPIError", apiError);
      }
    },
    updateOverviews: async (context, updateData) => {
      const isOrderOpened = updateData.order.status === 'open';

      const updatedOverview = context.state.overview.map(i =>
        i.id === updateData.updated_overview_item.id
          ? {
            ...i,
            ...updateData.updated_overview_item,
            highlight: isOrderOpened || i.highlight
          } : i);
      context.commit("refreshOverview", updatedOverview);

      if (isOrderOpened) {
        const message = `New available study: ${updateData.order.accession} in ${context.state.overview.find(l => l.id === updateData.order.product_id)?.name}`;
        await context.dispatch("raiseMessage", { message, status: "success", displayTime: 120000 });

        const orderProduct = context.state.overview.find(p => p.id === updateData.order.product_id);
        const audio = (orderProduct?.region ?? '').toLowerCase() === 'uk' ? new Audio('/new-study-uk-notif.wav') : new Audio('/new-study-notif.wav');
        orderProduct?.for === 'service' && audio.play();
      } else {
        await context.dispatch("raiseMessage", { message: 'Worklist is updated.', status: "success" });
      }
    },
    updateStudies: async (context, updateData) => {
      if (updateData.updated_overview_item?.id === context.state.service) {
        await context.dispatch("refreshStudies", { limit: "all" });
        await context.dispatch("raiseMessage", { message: 'Study List is updated.', status: "success" });
      }
    },
    getVdisForOverview: async (context, { overview, toBeHighlighted }) => {
      const res = await vdiService.getVDIs(context.state.msalObj.getActiveAccount().username, overview);
      let overviewWithVdis;
      let vdiError = false;
      if (res.error) {
        overviewWithVdis = overview;
        vdiError = true;
      } else {
        overviewWithVdis = overview.map((item) => Object.assign(item, {
          vdi: res.vdiMap[item.id],
          highlight: context.state.overview.find(l => l.id === item.id)?.highlight
        }));
      }

      if (toBeHighlighted) {
        overviewWithVdis.find(l => l.id === toBeHighlighted.product_id).highlight = true;
      }

      if (!vdiError) {
        context.commit("refreshOverview", overviewWithVdis);
      } else {
        const apiError = {
          message: "VDI API failed to return available VDIs.",
          error: res.error,
          service: "VDI API"
        };
        context.commit("setAPIError", apiError);
      }
    },
    clearHighlights: (context) => {
      context.commit("refreshOverview", context.state.overview.map(l => ({
        ...l,
        highlight: false
      })));
    },
    refreshStudies: async (context, { limit, forAllOperators }) => {
      if (context.state.service !== "" && context.state.status !== "") {
        const requestedOperator = forAllOperators ? "" : context.state.msalObj.getActiveAccount().username;
        const studies = await clientService.getStudies(context.state.service, context.state.status, requestedOperator, limit);
        if (!studies.error) {
          context.commit("refreshStudyList", studies?.map(s => ({
            ...s,
            processed_by: context.state.vdis.find(v => v.ae_title === s.report_ae)?.is_being_used_by?.slice(0, -14)?.split('.').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')
          })));
          await context.dispatch("selectWorklist", { service: context.state.service, status: context.state.status });
        } else {
          const apiError = {
            message: "Order API failed to retrieve studies.",
            error: studies.error,
            service: "Order API"
          };
          context.commit("refreshStudyList", []);
          context.commit("setAPIError", apiError);
        }
      }
      if (context.state.loading === true) {
        context.commit('setLoading', false);
      }
    },
    emptyStudies: (context) => {
      context.commit("refreshStudyList", []);
    },
    requestVdiAllocation: async (context, { vdiRequirements, serviceID, forTheSakeOf, extraVdi }) => {
      const anyAllocatedVDI = context.state.overview.find(l => l.vdi?.status === 'ALLOCATED' && vdiRequirements.includes(`loc:${l.vdi?.location}`));
      const anyForNonServiceVDI = context.state.overview.find(l => l.vdi?.for === 'non-service' && vdiRequirements.includes(`loc:${l.vdi?.location}`));
      if (anyForNonServiceVDI || (anyAllocatedVDI && forTheSakeOf === 'service')) {
        const message = 'You already have an allocated VDI. Please abandon one before retrying.';
        await context.dispatch("raiseMessage", { message, status: "error" });
        return false;
      }

      const message = 'Requesting VDI allocation, please wait...';
      await context.dispatch("raiseMessage", { message, status: "success", displayTime: 60000 });
      const successful_req = await vdiService.requestAllocation(context.state.msalObj.getActiveAccount().username, vdiRequirements, forTheSakeOf, extraVdi);
      if (!successful_req) {
        const message = 'There is a problem. Please try again in a few minutes. In the case of persistance, please report.';
        await context.dispatch("raiseMessage", { message, status: "error" });
        return false;
      }

      await context.dispatch("raiseMessage", { message: 'Please abandon the VDI when you are done with it.', status: "success" });
      await context.dispatch("refreshOverview");

      const service_object = context.state.overview.find(x => x.id === serviceID);
      context.commit("setReportingNode", { node: service_object && { ...service_object.vdi, requirements: service_object.echoGoCoreVersions, serviceID } });
      return true;
    },
    requestVdiAbandonment: async (context, { vdiName, rg, serviceID }) => {
      const message = 'Requesting VDI abandonment, please wait...';
      await context.dispatch("raiseMessage", { message, status: "success", displayTime: 60000 });
      const successful_req = await vdiService.requestAbandonment(vdiName, rg, context.state.msalObj.getActiveAccount().username);
      if (!successful_req) {
        const message = 'There is a problem. Please try again in a few minutes. In the case of persistance, please report.';
        await context.dispatch("raiseMessage", { message, status: "error" });
        return false;
      }

      await context.dispatch("raiseMessage", { message: 'The VDI is successfully abandoned.', status: "success" });

      await context.dispatch("refreshOverview");

      const service_object = context.state.overview.find(x => x.id === serviceID);
      context.commit("setReportingNode", { node: service_object && { ...service_object.vdi, requirements: service_object.echoGoCoreVersions, serviceID } });
      return true;
    },
    forceVdiAbandonment: async (context, { vdiName, rg }) => {
      const successful_req = await vdiService.requestAbandonment(vdiName, rg, context.state.msalObj.getActiveAccount().username);
      if (!successful_req) {
        const message = 'There is a problem. Please try again in a few minutes. In the case of persistance, please report.';
        await context.dispatch("raiseMessage", { message, status: "error" });
        return false;
      }

      await context.dispatch("raiseMessage", { message: 'The VDI is successfully abandoned.', status: "success" });

      await context.dispatch("refreshOverview");
      await context.dispatch("setVdis");
    },
    uploadVdiLogs: async (context, { vdiName, rg }) => {
      const successful_req = await vdiService.requestLogsUpload(vdiName, rg);
      if (!successful_req) {
        const message = 'There is a problem. Please try again in a few minutes. In the case of persistance, please report.';
        await context.dispatch("raiseMessage", { message, status: "error" });
        return false;
      }

      const message = 'The VDI is successfully abandoned.';
      await context.dispatch("raiseMessage", { message, status: "success" });
      await context.dispatch("refreshOverview");
      await context.dispatch("setVdis");
    },
    selectWorklist: async (context, { service, status }) => {
      context.commit("setWorklist", { service, status });
      const service_object = context.state.overview.find(x => x.id === service);
      context.commit("setReportingNode", { node: service_object && { ...service_object.vdi, requirements: service_object.echoGoCoreVersions, service_id: service } });
    },
    putStudyOnHold: async (context, { accession, errorBoundary }) => {
      const datetime = new Date().toISOString();
      let flashMessage = "";
      let onHoldReason = "";
      if (errorBoundary === "order") {
        flashMessage = `${accession} has been escalated as it could not be updated via the Order API.`;
        onHoldReason = "Failed to update study via Order API";
      }
      if (errorBoundary === "vna") {
        flashMessage = `${accession} has been escalated as it could not be sent to the VNA.`;
        onHoldReason = "Failed to send study to VNA";
      }
      const escalateStudy = await clientService.updateStudyStatus(accession, "intervene", "", onHoldReason, "SysAdmin", datetime);
      if (escalateStudy) {
        await context.dispatch("raiseMessage", { message: flashMessage, status: "error" });
      } else {
        await context.dispatch("raiseMessage", { message: `${accession} could not be put on hold.`, status: "error" });
      }
    },
    reportNext: async (context, { searchString }) => {
      context.commit("START_LOADING");
      let inprogressStudy = null;

      if (context.state.studies.length < 1) {
        await context.dispatch("raiseMessage", { message: 'No studies available', status: "error" });
      }
      if (!context.state.reporting_node) {
        await context.dispatch("raiseMessage", { message: 'No reporting node configured', status: "error" });
      }

      const nextStudy = selectNextStudy(context.state.studies, searchString);
      const isReportable = await clientService.reportStudy(nextStudy.accession, context.state.reporting_node.ae_title);

      if (isReportable) {
        context.commit("setOpenStudy", { data: null });
        const result = await vnaService.pushStudy(
          nextStudy.node_id,
          nextStudy.accession,
          context.state.reporting_node.ae_title,
          context.state.reporting_node.host,
          context.state.reporting_node.port,
          context.state.reporting_node.location,
          false,
          nextStudy.label_baseline,
          nextStudy.label_peak,
          nextStudy.clips
        );
        if (result.success) {
          inprogressStudy = nextStudy;
        } else {
          const flashMessage = `${nextStudy.accession} could not be sent. Please try again in a few minutes. In the case of persistance, please report.`;
          await context.dispatch("raiseMessage", { message: flashMessage, status: "error" });
        }
      } else {
        await context.dispatch("raiseMessage", { message: `Cannot progress ${nextStudy.accession}. Please wait a few moments and then try again; if the issue persists, raise a support ticket.`, status: "error" });
      }

      context.commit("STOP_LOADING");
      if (inprogressStudy) {
        await context.dispatch("raiseMessage", { message: `Reporting ${inprogressStudy.accession}`, status: "success" });
        await context.dispatch("refreshStudies", { limit: "all" });
        context.commit("setInprogressStudy", inprogressStudy);
      } else {
        await context.dispatch("raiseMessage", { message: 'No study was able to be sent', status: "error" });
      }
    },
    resendManual: async (context, { study, clips }) => {
      if (!study) {
        await context.dispatch("raiseMessage", { message: 'No study to resend', status: "error" });
        return;
      }
      const result = await vnaService.pushStudy(
        study.node_id,
        study.accession,
        context.state.reporting_node.ae_title,
        context.state.reporting_node.host,
        context.state.reporting_node.port,
        context.state.reporting_node.location,
        false,
        study.label_baseline,
        study.label_peak,
        clips
      );
      if (result) {
        await context.dispatch("raiseMessage", { message: `Manually resent ${study.accession}`, status: "success" });
      } else {
        await context.dispatch("raiseMessage", { message: `Cannot resend ${study.accession}`, status: "error" });
      }
    },
    retrieveStudyDetails: async (context, { accession }) => {
      const result = await clientService.findStudy(accession);
      if (result) {
        context.commit("setEscalationReason", result);
        context.commit("setQCForm", result);
        context.commit("setProductID", result.product_id);
      }
    },
    retrieveReports: async (context, { accession }) => {
      const result = await vnaService.loadReport(accession, context.state.reporting_node?.location);
      if (result) {
        context.commit("setStudyReport", result.data);
      }
    },
    completeReport: async (context, { accession, status, escalatedReason, escalatedReasonText, escalatedBy, escalationDatetime }) => {
      if (!accession) {
        await context.dispatch("raiseMessage", { message: 'No study to cancel', status: "error" });
        return;
      }
      const result = await clientService.updateStudyStatus(accession, status, escalatedReason, escalatedReasonText, escalatedBy, escalationDatetime);
      if (result) {
        await context.dispatch("raiseMessage", { message: `Order ${accession} set to ${status}`, status: "success" });
        await context.dispatch("refreshStudies", { limit: "all" });
        await context.dispatch("refreshOverview");
        if (status === 'intervene' && context.state.status === 'escalate') {
          // needed to display the latest escalation reason
          const node = context.state.open_study.node_id;
          await context.dispatch("openStudy", { node, accession });
        }
      } else {
        await context.dispatch("raiseMessage", { message: `Cannot update ${accession}`, status: "error" });
      }
    },
    saveQCForm: async (context, { accession, edit_point, qcForm }) => {
      if (!accession) {
        await context.dispatch("raiseMessage", { message: 'Study update requested - but no order number!', status: "error" });
        return;
      }
      const result = await clientService.saveQCForm(accession, edit_point, qcForm);
      if (result) {
        await context.dispatch("raiseMessage", { message: `Order ${accession} updated`, status: "success" });
      }
    },
    dicomSendStudy: async (context, { accession, ae_title, host, port }) => {
      // let cmove = await vnaService.pushStudy(accession, ae_title, host, port);
      // context.dispatch("setFlash", cmove);
    },
    setFlash: async (context, { message, status, displayTime }) => {
      context.commit("setFlash", { status, message });
      setTimeout(() => context.commit("clearFlash"), displayTime ?? 4000);
    },
    populateNotifications: async (context, { notificationArray }) => {
      if (notificationArray.length) {
        context.commit("setNotifications", { notificationArray });
      } else {
        context.commit("setNotifications", { notificationArray: [] });
        localStorage.setItem("Notifications", "[]");
      }
    },
    setNotificationMessage: async (context, { message, status, ticketLink }) => {
      const time = new Date().toISOString();
      const notifications = localStorage.getItem('Notifications');
      let savedNotifications;

      if (notifications !== "" && notifications !== null) {
        savedNotifications = JSON.parse(notifications);
      }

      if (savedNotifications) {
        const notificationCount = savedNotifications.length;
        if (notificationCount >= 99) {
          savedNotifications.splice(98 - notificationCount);
        }
        savedNotifications.unshift({ message, status, time, ticketLink });
        const newNotificationStr = JSON.stringify(savedNotifications);
        localStorage.setItem('Notifications', newNotificationStr);
      } else {
        const newNotificationStr = JSON.stringify([{ message, status, time, ticketLink }]);
        localStorage.setItem('Notifications', newNotificationStr);
      }
      context.commit("setNotification", { message, status, time, ticketLink });
    },
    raiseMessage: async (context, { message, status, ticketLink, displayTime }) => {
      if (displayTime) {
        await context.dispatch("setFlash", { message, ticketLink, status, displayTime });
      } else {
        await context.dispatch("setFlash", { message, ticketLink, status });
      }
      const translateStatus = (status: string) => {
        switch (status) {
          case "error":
            return "alert-danger";
          case "success":
            return "alert-success";
          default:
            return "";
        }
      };
      const translatedStatus = translateStatus(status);
      await context.dispatch("setNotificationMessage", { message, status: translatedStatus, ticketLink: ticketLink });
    },
    openStudy: async (context, { node_id, accession }) => {
      context.commit("setOpenStudy", { data: null });
      const result = await vnaService.loadStudy(node_id, accession, context.state.reporting_node.location);
      if (result.success) {
        context.commit("setOpenStudy", { data: result.data });
      } else {
        context.commit("setOpenStudyFailed");
        context.dispatch("raiseMessage", { message: 'Study failed to load, please go back and retry.', status: "error" });
      }
    }
  },
  modules: {
  }
});
