import axios from "axios";
import store from '../store';
import env from '../env.json';

const haloClient = axios.create();

export default {
  sendHaloSupportInfo: async (userEmail, study, supportDescription) => {
    const details = (study.accession ? `${study.accession}: ` : '') + `Submitted from Worklist dashboard by ${userEmail}`;
    const response = await haloClient.post(`${env.VUE_APP_HALO_API}api/tickets`,
      [{
        user_email: userEmail,
        customfields: [{
          name: "CFStudyID",
          value: study.accession
        },
        {
          name: "CFOperator",
          value: study.operator
        },
        {
          name: "CForderproductid",
          value: study.product_id
        },
        {
          name: "CFCFAssociationStartTime",
          value: study.associationStartTime
        },
        {
          name: "CFPSQDeviceInfo",
          value: 'Not set'
        },
        {
          name: "CFPSQReportOutput",
          value: 'Not set'
        },
        {
          name: "CFPSQSLA",
          value: 'Not set'
        },
        {
          name: "CFPSQHarm",
          value: 'Not set'
        }],
        summary: `${supportDescription}`,
        details: details,
        tickettype_id: 25 // OPS-1861 Halo ticket type set to 'Operations support'
      }],
      { headers: { Authorization: `Bearer ${localStorage.getItem('haloAccessToken')}`, 'content-type': 'application/json' } }
    );
    return response.data;
  },
  authenticateWithHalo: async () => {
    const params = {
      tenant: env.VUE_APP_HALO_TENANT,
      client_id: env.VUE_APP_HALO_CLIENT_ID,
      response_type: 'token',
      scope: 'all',
      redirect_uri: env.VUE_APP_HALO_REDIRECT_URI
    };
    store.commit('setWorklistRedirectUrl', window.location.pathname);
    window.location.href = `${env.VUE_APP_HALO_API}auth/authorize?client_id=${params.client_id}&tenant=${params.tenant}&response_type=${params.response_type}&scope=${params.scope}&redirect_uri=${params.redirect_uri}`;
  },
  submitSupportInfoToHalo: async (component, supportDescription: string, study) => {
    try {
      component.showHaloLoadingSymbol = true;
      const response = await component.sendSupportInfo(study, supportDescription);
      component.$store.dispatch('raiseMessage', { message: `Ticket ${response.reference} has been submitted.`, ticketLink: `${env.VUE_APP_HALO_API}portal/ticket?btn=60&viewid=0&id=${response.id}`, status: "success", displayTime: 120000 });
      component.showHaloLoadingSymbol = false;
    } catch (error) {
      if (error instanceof TypeError) {
        // eslint-disable-next-line
        console.error(error);
      } else {
        throw error;
      }
    }
  },
  haloAccessTokenIsExpired: () => {
    const expiryTime = localStorage.getItem('haloAccessTokenExpiryTime');
    const currentTime = new Date().getTime();
    return (currentTime > parseInt(expiryTime));
  }
};
