
import ScoreboardReportStatus from "@/components/ScoreboardReportStatus.vue";

export default {
  props: {
    report: Object,
    study: Object,
    index: Number
  },
  components: {
    ScoreboardReportStatus
  },
  computed: {
    first_result () {
      if (this.study.first_result === undefined) {
        return "";
      }
      return this.study.first_result + "Z";
    }
  },
  methods: {
    getSoftware (studySoftware: string, version?: string) {
      if (version) {
        studySoftware = studySoftware + ": " + version;
      }
      if (studySoftware.includes("EchoGo")) {
        return studySoftware.substring(7);
      }
      return studySoftware;
    },
    getStatus (study) {
      let status = "";
      study.results.forEach((report) => {
        if (report.pending) {
          status = "pending";
        } else if (report.accepted) {
          status = "finished";
        }
      });
      if (study.results.length === 0) {
        status = "no report";
      }
      return status;
    }
  }
};
