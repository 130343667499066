
import { generateServiceTypes } from "@/util/index";
import { mapGetters } from "vuex";

export default {
  props: {
    serviceOverview: Array,
    region: String
  },
  components: {},
  data () {
    return {
      serviceTypes: []
    };
  },
  created () {
    this.serviceTypes = generateServiceTypes(this.serviceOverview);
  },
  computed: {
    ...mapGetters({
      apiError: "getAPIError"
    }),
    errorInAvailableVDIs () {
      return this.serviceOverview.vdiError;
    }
  },
  filters: {
    upper: function (value: string) {
      return value.toLocaleUpperCase();
    }
  },
  watch: {
    serviceOverview: {
      deep: true,
      handler (incomingServiceOverview) {
        this.serviceTypes = generateServiceTypes(incomingServiceOverview);
      }
    }
  }
};
