
export default {
  props: {
    vdiList: Array
  },
  methods: {
    requestAbandonment: async function (vdi) {
      await this.$store.dispatch("forceVdiAbandonment", { vdiName: vdi.name, rg: vdi.resource_group });
    },
    requestLogsUpload: async function (vdi) {
      await this.$store.dispatch("uploadVdiLogs", { vdiName: vdi.name, rg: vdi.resource_group });
    }
  }
};
