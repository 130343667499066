
import HealthListItem from '@/components/HealthListItem.vue';
import ScoreboardService from "@/services/scoreboard";
import clientService from "@/services";
import VDIService from "@/services/vdi";
import VNA from "@/services/vna";
import { mapGetters } from "vuex";

const scoreboardService = new ScoreboardService();
const vdiService = new VDIService();
const vnaService = new VNA();

export default {

  name: "health",
  data () {
    return {
      serviceStatuses: []
    };
  },
  computed: {
    ...mapGetters({
      config: "getConfig"
    })
  },
  async beforeMount () {
    this.$store.dispatch("toggleLoading", true);

    vdiService.setBaseUrl(this.config.config.vdi_service_api);
    clientService.setBaseUrl(this.config.config.order_api);
    vnaService.setBaseUrl(this.config.config.order_api_vna);
    scoreboardService.setBaseUrl(this.config.config.data_service_api);

    const [vdiStatus, orderStatus, vnaStatusUS, pubsubStatus, dataExportStatus] = await Promise.all([
      vdiService.getVDIHealthStatus(),
      clientService.getOrderAPIHealthStatus(),
      vnaService.healthCheck("us"),
      clientService.checkPubSubConnection(),
      scoreboardService.healthcheck()
    ]);

    const vdiStatusReport = this.generateStatusReport("VDI API", vdiStatus);
    const orderStatusReport = this.generateStatusReport("Order API", orderStatus);
    const vnaStatusUSReport = this.generateStatusReport("VNA API US", vnaStatusUS);
    const pubsubReport = this.generateStatusReport("PubSub Connection", pubsubStatus);
    const dataExportReport = this.generateStatusReport("Data Exporter", dataExportStatus);

    vnaService.setBaseUrl(this.config.config.order_api_vna_uk, "uk");
    const vnaStatusUK = await vnaService.healthCheck("uk");
    const vnaStatusUKReport = this.generateStatusReport("VNA API UK", vnaStatusUK);

    this.serviceStatuses.push(orderStatusReport, vdiStatusReport, vnaStatusUSReport, vnaStatusUKReport, pubsubReport, dataExportReport);

    this.$store.dispatch("toggleLoading", false);
  },
  methods: {
    retrieveMessage (data: any) {
      let outputMsg;
      if (data.message) {
        outputMsg = data.message;
      } else if (data.data) {
        outputMsg = data.data;
      } else {
        outputMsg = data ?? "N/A";
      }
      return outputMsg;
    },
    retrieveHTTPStatus (data: any) {
      let httpStatus;
      if (data.status === 200 || data.status === true || data.status === "OK") {
        httpStatus = 200;
      } else if (data.success === 200 || data.success === true || data.success === "OK") {
        httpStatus = 200;
      } else {
        httpStatus = 500;
      }
      return httpStatus;
    },
    generateStatusReport (name: string, data: any) {
      const report = {
        serviceName: name,
        status: this.retrieveHTTPStatus(data),
        output: data ? this.retrieveMessage(data) : "N/A"
      };
      return report;
    }
  },
  components: {
    HealthListItem
  }
};
