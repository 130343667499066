
import ScoreboardListReport from "@/components/ScoreboardListReport.vue";

export default {
  props: {
    report: Object,
    study: Object,
    index: Number
  },
  components: {
    ScoreboardListReport
  }
};
