
import VdisOverview from "@/components/VDIsOverview.vue";
import { mapGetters } from "vuex";

export default {
  name: "vdis",
  computed: {
    ...mapGetters({
      vdiList: "getVdis"
    })
  },
  components: {
    VdisOverview
  }
};
