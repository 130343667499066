
export default {
  computed: {
    statusIcon () {
      const tickPath = "/icons/tick.svg";
      const crossPath = "/icons/cross.svg";
      if (this.service.status >= 200 && this.service.status < 300) {
        return tickPath;
      }
      return crossPath;
    }
  },
  props: {
    service: Object
  }
};
