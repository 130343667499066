
import StudyList from "@/components/StudyList.vue";
import { mapGetters } from "vuex";

export default {
  name: "escalationlist",
  data () {
    return {
      selected: null,
      scrollPosition: null,
      showAssignedStudies: false
    };
  },
  mounted () {
    window.addEventListener("scroll", this.updateScroll);
  },
  computed: {
    ...mapGetters({
      ready: "ready",
      getReportable: "getReportable",
      isSupportGroupMember: "getIsSupportGroupMember",
      studies: "getStudies"
    })
  },
  components: {
    StudyList
  },
  methods: {
    refresh () {
      this.$store.dispatch("refreshStudies", { limit: "all" });
    },
    selectStudy (study) {
      this.selected = study;
    },
    async openStudy () {
      this.$router.push({
        name: "manualStudy",
        params: { accession: this.selected.accession }
      });
    },
    toggleAssignedStudies: function () {
      this.showAssignedStudies = !this.showAssignedStudies;
      this.$store.commit('setLoading', true);
      this.$store.dispatch('refreshStudies', { limit: "all", forAllOperators: this.showAssignedStudies });
    },
    updateScroll () {
      this.scrollPosition = window.scrollY;
    }
  }
};
