
export default {
  props: {
    report: Object,
    study: Object
  },
  computed: {
    is_rejected () {
      if (
        !this.report.accepted &&
        !this.report.pending &&
        this.report.reason !== undefined &&
        this.report.reason !== ""
      ) {
        return true;
      }
      return false;
    }
  },
  methods: {
    hasTooltipValue (value: string) {
      if (value) {
        return "tooltip-text";
      }
    }
  }
};
