import { OverviewItem, StudyItem } from "@/types";

type OverviewArray = OverviewItem[];
type StudyArray = StudyItem[];

export const generateServiceTypes = (overview: OverviewArray) => {
  const preferred_order = ["service", "demo", "validation", "retrospective", "research", "support", "training", "test", "quality"];
  const types = Array.from(new Set(overview.map(s => s.for || ""))).filter(t => t !== "") as string[];
  if (!types) {
    return [];
  }
  types.sort((a, b) => {
    let pos_a = preferred_order.indexOf(a);
    let pos_b = preferred_order.indexOf(b);
    if (pos_a < 0) pos_a = 100;
    if (pos_b < 0) pos_b = 100;
    return pos_a - pos_b;
  });
  const results = [];
  // use of eslist disable below - simple rule mis-parse, it doesn't like the dangling comma,
  // presumably because it doesn't realise we're in an object.
  for (const label of types) {
    results.push({
      label,
      readable: label.substring(0, 1).toUpperCase() + label.substring(1), // eslint-disable-line
      services: overview.filter(s => s.for === label)
    });
  }
  return results;
};

export const isObjectEmpty = (obj: Object) => {
  return Object.keys(obj).length === 0;
};

export const selectNextStudy = (studies: StudyArray, searchString: string) => {
  for (const study of studies) {
    if (study.status !== "open") {
      continue;
    }

    if (searchString &&
      !study.contract.toLowerCase().includes(searchString.toLowerCase()) &&
      !study.accession.toLowerCase().includes(searchString.toLowerCase())) {
      continue;
    }
    return study;
  }
};

export const wait = function (waitingTime?: number) {
  return new Promise(resolve => {
    setTimeout(resolve, waitingTime);
  });
};
