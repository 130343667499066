import { render, staticRenderFns } from "./VDIsOverview.vue?vue&type=template&id=5510ca86&scoped=true&"
import script from "./VDIsOverview.vue?vue&type=script&lang=ts&"
export * from "./VDIsOverview.vue?vue&type=script&lang=ts&"
import style0 from "./VDIsOverview.vue?vue&type=style&index=0&id=5510ca86&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5510ca86",
  null
  
)

export default component.exports