import axios from "axios";

const URL = 'https://graph.microsoft.com/v1.0/me/';

const httpClient = axios.create({
  baseURL: URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export default {
  setAuthenticationToken: (token: string) => {
    httpClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  },
  getGroupMemberships: async () => {
    const res = await httpClient.get("memberOf");
    return res;
  }
};
