import axios from "axios";

class VNAClient {
  locations: { [key: string]: any; } = {};

  setBaseUrl (baseURL: string, location = "us") {
    // TODO: authentication
    this.locations[location] = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  async pushStudy (node_id: string, accession: string, ae_title: string, host: string, port: string, location = "us", cropped = false, label_baseline: string, label_peak: string, clips = null) {
    if (!this.locations[location]) {
      return {
        success: false,
        status: 501,
        message: "No VNA provided for location"
      };
    }
    try {
      const result = await this.locations[location].post('api/movestudy', {
        node_id,
        accession,
        aetitle: ae_title,
        host,
        port,
        crop: cropped,
        noorder: true,
        label_baseline,
        label_peak,
        clips
      });

      return {
        success: result.status === 200,
        status: result.status,
        message: result.data
      };
    } catch (e) {
      return {
        success: false,
        status: 500,
        message: e.toString()
      };
    }
  }

  async loadStudy (node_id, accession, location = "us") {
    try {
      const result = await this.locations[location].post('api/LoadStudy', {
        node_id,
        accession,
        metadata: true
      });

      return {
        success: result.status === 200,
        data: result.data
      };
    } catch (e) {
      return {
        success: false,
        message: e.toString()
      };
    }
  }

  async loadReport (accession, location = "us") {
    try {
      const result = await this.locations[location].post('api/LoadReport', {
        accession
      });

      return {
        success: result.status === 200,
        data: result.data
      };
    } catch (e) {
      return {
        success: false,
        message: e.toString()
      };
    }
  }

  async ping (ae_title, host, port, location = "us") {
    try {
      const result = await this.locations[location].post('/tools/c-echo', {
        aetitle: ae_title,
        host: host,
        port: port
      });

      return {
        success: result.status === 200,
        message: result.data
      };
    } catch (e) {
      return {
        success: false,
        message: e.toString()
      };
    }
  }

  async healthCheck (location: string) {
    try {
      const result = await this.locations[location].get('/api/HealthCheck');
      return {
        success: result.data.status,
        message: result.data.message
      };
    } catch (e) {
      return {
        success: false,
        message: e.toString()
      };
    }
  }
}

export default VNAClient;
