
import WorklistOverview from "@/components/WorklistOverview.vue";
import { mapGetters } from "vuex";
import { WebPubSubServiceClient } from "@azure/web-pubsub";

export default {
  name: "dashboard",
  data () {
    return {
      connection: null
    };
  },
  computed: {
    ...mapGetters({
      ready: "ready",
      overview: "getOverview",
      pubsubConnectionString: "getPubsubConnectionString"
    })
  },
  components: {
    WorklistOverview
  },
  async created () {
    if (!this.$store.state.userAccessToken) return;

    const service = new WebPubSubServiceClient(this.pubsubConnectionString, "pubsub");
    const token = await service.getClientAccessToken();
    this.connection = new WebSocket(token.url);
    this.connection.onmessage = async (event) => {
      await this.$store.dispatch("updateOverviews", JSON.parse(event.data));
    };
    this.$store.dispatch("setServiceTypes", this.overview);
  },
  async destroyed () {
    await this.$store.dispatch("clearHighlights");
    this.connection && this.connection.close();
  }
};
