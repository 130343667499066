
import clientService from "@/services/halo";
import { mapGetters } from "vuex";

export default {
  props: {
    studyForSupport: Object,
    inputPlaceholder: String,
    inputWidth: Number
  },
  data () {
    return {
      supportDescription: "",
      showHaloLoadingSymbol: false,
      inputHeight: 'auto'
    };
  },
  computed: {
    isInputEmpty () {
      return this.supportDescription.length === 0;
    },
    ...mapGetters({
      userEmail: "getUserEmail"
    }),
    isAuthenticatedWithHalo () {
      return !(localStorage.getItem('haloAccessToken') == null || clientService.haloAccessTokenIsExpired());
    }
  },
  methods: {
    onInput () {
      const input = this.$refs.input;
      input.style.height = 'auto';
      input.style.height = `${input.scrollHeight}px`;
    },
    async sendSupportInfo (study, supportDescription) {
      try {
        this.showHaloLoadingSymbol = true;
        return await clientService.sendHaloSupportInfo(this.userEmail, study, supportDescription);
      } catch (err) {
        // eslint-disable-next-line
        console.error(err);
      }
      this.showHaloLoadingSymbol = false;
    },
    async authenticateWithHalo () {
      try {
        this.showHaloLoadingSymbol = true;
        await clientService.authenticateWithHalo();
      } catch (err) {
        this.$store.dispatch('raiseMessage', { message: `Failed to authenticate with Halo`, status: "error", displayTime: 120000 });
      }
      this.showHaloLoadingSymbol = false;
    },
    async submitSupportInfoToHalo (supportDescription: string, study) {
      try {
        this.showHaloLoadingSymbol = true;
        await clientService.submitSupportInfoToHalo(this, supportDescription, study);
      } catch (error) {
        this.$store.dispatch('raiseMessage', { message: `Failed to submit support ticket to Halo`, status: "error", displayTime: 120000 });
      }
      this.showHaloLoadingSymbol = false;
      this.$emit("toggleShowSupportBox");
    }
  }
};
