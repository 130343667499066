import axios, { Axios, AxiosInstance } from "axios";
import { wait } from '../util';
import { Node } from "@/types";

export const enum VDIStatus {
  UNRESPONSIVE = "CURRENTLY UNRESPONSIVE",
  OFF = "OFF",
  UPDATING = "UPDATING",
  ON = "ON"
}

class VDIService {
  axios: AxiosInstance;

  setBaseUrl (baseURL: string) {
    this.axios = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  retryWrapper (axios: Axios, retryCount: number, secondsToWait: number, errorCode: number) {
    let counter = 0;
    axios.interceptors.response.use((response) => {
      return response;
    }, (error) => {
      if (error.response.status === errorCode) {
        if (counter < retryCount) {
          counter++;
          return new Promise((resolve, reject) => {
            wait(secondsToWait).then(() => {
              this.axios.request(error.config).then((response) => {
                resolve(response);
              }).catch((error) => {
                reject(error);
              });
            });
          });
        }
      } else {
        return Promise.reject(error);
      }
    });
  }

  async getVDIs (username, overview) {
    try {
      const vdiAssignmentList = await this.axios.post('/api/vdi', {
        username,
        overview
      });
      return {
        vdiMap: vdiAssignmentList.data,
        error: vdiAssignmentList.status !== 200
      };
    } catch (error) {
      return {
        vdiMap: [],
        error: error.toString()
      };
    }
  }

  async requestAllocation (username: string, requirements: string[], forTheSakeOf: string, extraVdi: boolean) {
    try {
      const res = await this.axios.post('/api/claim-vdi', {
        operator: username,
        requirements,
        for: forTheSakeOf,
        extraVdi
      });
      return res.status >= 200 && res.status < 300;
    } catch (error) {
      return false;
    }
  }

  async requestAbandonment (vdiName: string, resourceGroup: string, operator: string) {
    try {
      const res = await this.axios.post('/api/abandon-vdi', {
        vdiName,
        operator,
        vdiResourceGroup: resourceGroup
      });
      return res.status >= 200 && res.status < 300;
    } catch (error) {
      return false;
    }
  }

  async getAllVdis () {
    try {
      const res = await this.axios.get('/api/allVdis');
      return res.status >= 200 && res.status < 300 ? res.data : [];
    } catch (error) {
      return [];
    }
  }

  async requestLogsUpload (vdiName: string, resourceGroup: string) {
    try {
      const res = await this.axios.post('/api/upload-logs', {
        vdiName,
        resourceGroup
      });
      return res.status >= 200 && res.status < 300;
    } catch (error) {
      return false;
    }
  }

  async getVDIHealthStatus () {
    try {
      const res = await this.axios.get('/api/healthcheck');
      return {
        success: res.status === 200,
        message: res.data
      };
    } catch (error) {
      return {
        success: false,
        message: error.toString()
      };
    }
  }

  async getVDIPowerStatus (subscription: string, vdiResourceGroup: string, vdiName: string) {
    try {
      this.retryWrapper(this.axios, 3, 3000, 500);
      const res = await this.axios.post('/api/vdi-power-status', {
        subscription,
        vdiResourceGroup,
        vdiName
      });
      return res.data;
    } catch (error) {
      return {
        success: false,
        message: error.toString()
      };
    }
  }

  async switchVDIPower (subscription: string, vdiResourceGroup: string, vdiName: string, operator: string, power: boolean) {
    try {
      const res = await this.axios.post('/api/switch-vdi-power', {
        subscription,
        vdiResourceGroup,
        vdiName,
        operator,
        power
      });
      return {
        status: res.status,
        message: res.data
      };
    } catch (error) {
      return {
        status: 500,
        message: error.toString()
      };
    }
  }

  getClaimedVdiInfo (node: Node) {
    const portalURL = node.portal_url;
    const subscriptionID = portalURL.split("subscriptions/")[1].split("/resourceGroups")[0];
    const resourceGroupName = portalURL.split("resourceGroups/")[1].split("/providers")[0];
    const vmName = portalURL.split("virtualMachines/")[1].split("/overview")[0];
    return {
      subscriptionID,
      resourceGroupName,
      vmName
    };
  }

  interpretVdiStatus (status: string): VDIStatus {
    switch (status) {
      case 'running':
        return VDIStatus.ON;
      case 'deallocated':
        return VDIStatus.OFF;
      case 'updating':
      case 'deallocating':
      case 'starting':
        return VDIStatus.UPDATING;
      default:
        return VDIStatus.UNRESPONSIVE;
    }
  }

  async retrieveVDIPowerStatus (subscriptionID, resourceGroupName, vmName) {
    const res = await this.getVDIPowerStatus(subscriptionID, resourceGroupName, vmName);
    if (res.message) {
      return VDIStatus.UNRESPONSIVE;
    } else {
      const vdiStatus = this.interpretVdiStatus(res);
      return vdiStatus;
    }
  }
}

export default VDIService;
