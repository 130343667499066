
import { mapGetters } from "vuex";
import HaloSupportBox from "@/components/HaloSupportBox.vue";

export default {
  props: ['allowselect', 'selected', 'study', 'reasons', 'isEscalated', 'showSupportBox', 'studyForSupport', 'inputPlaceholder'],
  data () {
    return {
      showReason: false
    };
  },
  computed: {
    ...mapGetters({
      isSupportGroupMember: "getIsSupportGroupMember",
      vdis: "getVdis"
    }),
    viewReason: function () {
      if (this.showReason) {
        return "⯇";
      }
      return "⯈";
    }
  },
  methods: {
    toggleShowSupportBox (study) {
      this.$emit('toggleShowSupportBox', study);
    },
    showSupportBoxOnRow (study, studyForSupport, showSupportBox) {
      return (study === studyForSupport) && showSupportBox;
    },
    toggleViewReason: function () {
      this.showReason = !this.showReason;
    }
  }
};
