
import StudyList from "@/components/StudyList.vue";
import { WebPubSubServiceClient } from "@azure/web-pubsub";
import { mapGetters } from "vuex";

export default {
  name: "studylist",
  data () {
    return {
      showAssignedStudies: false,
      searchString: '',
      connection: null
    };
  },
  computed: {
    reporting () {
      return this.getInprogressStudy != null;
    },
    studiesAvailable () {
      if (!this.getInprogressStudy && this.studies.length) {
        return true;
      }
      return false;
    },
    product () {
      return this.studies[0]?.product;
    },
    ...mapGetters({
      ready: "ready",
      getReportable: "getReportable",
      getInprogressStudy: "getInprogressStudy",
      studies: "getStudies",
      is_loading: "is_loading",
      isSupportGroupMember: "getIsSupportGroupMember",
      selectedServiceType: "getSelectedServiceType",
      pubsubConnectionString: "getPubsubConnectionString"
    })
  },
  components: {
    StudyList
  },
  methods: {
    report: async function () {
      await this.$store.dispatch("reportNext", { searchString: this.searchString });
      if (this.getInprogressStudy) {
        this.$router.push({ name: "manualStudy", params: { accession: this.getInprogressStudy.accession } });
      }
    },
    toggleAssignedStudies: function () {
      this.showAssignedStudies = !this.showAssignedStudies;
      this.$store.commit('setLoading', true);
      this.$store.dispatch('refreshStudies', { limit: "all", forAllOperators: this.showAssignedStudies });
    },
    // completeReport: function() {
    //   this.$store.dispatch("completeReport", { accession: this.getInprogressStudy.accession, status: "done" });
    // },
    cancelReport: function () {
      this.$store.dispatch("completeReport", { accession: this.getInprogressStudy.accession, status: "open" });
    },
    openStudy: async function () {
      this.$router.push({ name: "manualStudy", params: { accession: this.getInprogressStudy.accession } });
    },
    // resendCropped: function() {
    //   this.$store.dispatch("resendCropped");
    // },
    changeSearchString: function (searchString) {
      this.searchString = searchString;
    }
  },
  async created () {
    const service = new WebPubSubServiceClient(this.pubsubConnectionString, "pubsub");
    const token = await service.getClientAccessToken();
    this.connection = new WebSocket(token.url);
    this.connection.onmessage = async (event) => {
      await this.$store.dispatch("updateStudies", JSON.parse(event.data));
    };
  },
  destroyed () {
    this.showAssignedStudies = false;
    this.$store.dispatch("emptyStudies");
    this.connection.close();
  }
};
