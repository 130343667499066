
export default {
  methods: {
    selectClip (clip) {
      this.$emit("select-clip", clip);
    },
    toggleSeriesState (series) {
      this.$emit("toggle-series-state", series);
    },
    toggleStageState (stage) {
      this.$emit("toggle-stage-state", stage);
    },
    toggleHFClips () {
      this.$emit("toggle-hf-clips");
    }
  },
  props: {
    openStudyClips: Array,
    studies: Array,
    showUnselected: Boolean
  }
};
