
import { mapGetters } from 'vuex';
import PowerButton from './PowerButton.vue';

export default {
  name: "RDPLogin",
  components: {
    PowerButton
  },
  data: () => {
    return {
      subscriptionID: "",
      resourceGroupName: "",
      vmName: ""
    };
  },
  computed: {
    ...mapGetters({
      node: "getReportingNode",
      msalObj: "getMsalObj",
      selectedService: "getSelectedService"
    }),
    canClaimExtraVDI: function () {
      if (this.selectedService && this.node && this.node.status === 'ALLOCATED' && this.node.for !== 'non-service' && this.selectedService.for !== 'service') {
        return true;
      }
      return false;
    },
    serviceID: function () {
      return this.$route.params.service_id;
    },
    content: function () {
      return {
        app: this.node?.app ?? "",
        ae_title: this.node?.ae_title ?? "",
        location: this.node?.location?.toUpperCase() ?? "",
        general_access: this.node?.general_access ?? false
      };
    },
    rdpContent: function () {
      // https://docs.microsoft.com/en-us/windows-server/remote/remote-desktop-services/clients/rdp-files
      let rdpText = `full address:s:${this.node.host}`;
      if (this.msalObj.getActiveAccount().username) {
        rdpText += `\nusername:s:${this.msalObj.getActiveAccount().username}`;
      }
      const fileName = this.fileName;

      return 'data:application/rdp;' + fileName + ';charset=utf-8,' + encodeURIComponent(rdpText);
    },
    fileName: function () {
      return `${this.node.ae_title}.rdp`;
    }
  },
  methods: {
    requestAllocation: async function (vdiRequirements: string[], serviceID: string, serviceFor: string, extraVdi?: boolean) {
      await this.$store.dispatch("requestVdiAllocation", { vdiRequirements, serviceID, forTheSakeOf: serviceFor, extraVdi });
    },
    requestAbandonment: async function (vdi, serviceID: string) {
      await this.$store.dispatch("requestVdiAbandonment", { vdiName: vdi.name, rg: vdi.resource_group, serviceID });
    },
    refreshAvailability: async function (serviceID: string) {
      await this.$store.dispatch("refreshOverview");
      const service_object = this.$store.state.overview.find(x => x.id === serviceID);
      this.$store.commit("setReportingNode", { node: service_object && { ...service_object.vdi, requirements: service_object.echoGoCoreVersions, service_id: serviceID } });
    }
  }
};
