
import { mapGetters } from 'vuex';
import Notification from "./Notification.vue";

export default {
  name: "Navigation",
  components: {
    Notification
  },
  computed: {
    ...mapGetters({
      isUserAvailable: "getIsUserAvailable",
      isSupportGroupMember: "getIsSupportGroupMember",
      msalObj: "getMsalObj"
    }),
    canMarkAsSeen () {
      if (this.notificationCount && this.newNoticationPresent) {
        return true;
      }
      return false;
    }
  },
  methods: {
    logout: function () {
      this.msalObj.logoutRedirect({
        account: this.msalObj.getAccountByUsername(this.msalObj.getActiveAccount().username),
        postLogoutRedirectUri: '/login'
      });
    }
  }
};
