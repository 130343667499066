
import { mapGetters } from 'vuex';
import VDIService, { VDIStatus } from "@/services/vdi";
const vdiService = new VDIService();

export default {
  name: "PowerButton",
  data: () => {
    return {
      vdiPowerStatus: "",
      vdiRefreshCount: 0,
      subscriptionID: "",
      resourceGroupName: "",
      vmName: "",
      timerCount: 0,
      pollCountdown: null,
      powerSwitchClicked: false,
      desiredPowerStatus: '',
      showConfirmation: false
    };
  },
  computed: {
    ...mapGetters({
      config: "getConfig",
      node: "getReportingNode",
      userEmail: "getUserEmail",
      vdis: "getVdis"
    }),
    vdiPowerText: function () {
      return this.vdiPowerStatus === VDIStatus.ON ? 'Off' : 'On';
    },
    vdiColour: function () {
      if (this.vdiPowerChanging) {
        return "btn-warning disabled";
      } else if (this.vdiPowerStatus === VDIStatus.ON) {
        return "btn-success";
      } else if (this.vdiPowerStatus === VDIStatus.OFF) {
        return "btn-danger";
      } else {
        return "btn-warning";
      }
    },
    showVDIPower: function () {
      return this.vdiRefreshCount < 5;
    },
    serviceID: function () {
      return this.$route.params.service_id;
    },
    content: function () {
      return {
        app: this.node?.app ?? "",
        ae_title: this.node?.ae_title ?? "",
        location: this.node?.location?.toUpperCase() ?? "",
        general_access: this.node?.general_access ?? false
      };
    },
    vdiPowerChanging: function () {
      return this.vdiPowerStatus === VDIStatus.UPDATING || this.vdiPowerStatus === VDIStatus.UNRESPONSIVE || this.powerSwitchClicked;
    }
  },
  async created () {
    if (this.node) {
      vdiService.setBaseUrl(this.config.config.vdi_service_api);
      const vdiInfo = vdiService.getClaimedVdiInfo(this.node);
      this.subscriptionID = vdiInfo.subscriptionID;
      this.resourceGroupName = vdiInfo.resourceGroupName;
      this.vmName = vdiInfo.vmName;
      if (this.node.status === 'DEDICATED' || this.node.status === 'ALLOCATED') {
        this.vdiPowerStatus = await vdiService.retrieveVDIPowerStatus(this.subscriptionID, this.resourceGroupName, this.vmName);
        this.desiredPowerStatus = this.vdiPowerStatus === VDIStatus.ON ? 'OFF' : 'ON';
      }
    }
  },
  methods: {
    toggleConfirmation () {
      this.showConfirmation = !this.showConfirmation;
    },
    initiateVdiPolling: async function () {
      if (this.pollCountdown) {
        clearInterval(this.pollCountdown);
      }
      this.timerCount = 10;
      this.pollCountdown = setInterval(async () => {
        this.timerCount--;
        if (this.timerCount === 0 && this.vdiRefreshCount < 5 && this.vdiPowerStatus !== this.desiredPowerStatus) {
          this.vdiRefreshCount++;
          this.timerCount = 10;
          this.vdiPowerStatus = await vdiService.retrieveVDIPowerStatus(this.subscriptionID, this.resourceGroupName, this.vmName);
          if (this.vdiPowerStatus !== VDIStatus.UNRESPONSIVE) {
            this.powerSwitchClicked = false;
          }
        }
      }, 1000);
    },
    refreshAvailability: async function (serviceID: string) {
      await this.$store.dispatch("refreshOverview");
      const service_object = this.$store.state.overview.find(x => x.id === serviceID);
      this.$store.commit("setReportingNode", { node: service_object && { ...service_object.vdi, requirements: service_object.echoGoCoreVersions, service_id: serviceID } });
    },
    refreshClaimedVDI: async function () {
      await this.refreshPollingCountdown();
      await this.$store.dispatch("setVdis");
      const myVDI = this.vdis.find(v => v.ae_title === this.content.ae_title);
      if (myVDI?.is_being_used_by !== this.userEmail) {
        const vdiOfInterest = this.vdis.find(v => v.ae_title === this.content.ae_title);
        const vdiOwner = vdiOfInterest.is_being_used_by;
        this.$store.dispatch("raiseMessage", { message: `You cannot switch this VDI ${this.vdiPowerText.toLowerCase()}`, status: "error" });
      }
    },
    handleVDISwitch: async function (res: any, power: string) {
      const msg = res.message.includes("AxiosError") ? `Unable to turn VDI ${power}. Try again shortly or navigate to the Azure Portal.` : res.message;
      this.$store.dispatch("raiseMessage", { message: msg, status: res.status === 200 ? "success" : "error" });
      if (res.status === 200) {
        await this.initiateVdiPolling(this.desiredPowerStatus, this.subscriptionID, this.resourceGroupName, this.vmName);
      }
    },
    switchVDI: async function () {
      this.showConfirmation = false;
      const switchBtn = document.getElementsByClassName("vdiBackground")[0];
      switchBtn.classList.remove("show");
      const dropdown = document.getElementsByClassName("btn-group")[0];
      dropdown.classList.remove("show");
      document.getElementById("btnGroupDrop1").ariaExpanded = "false";
      this.desiredPowerStatus = this.vdiPowerStatus === VDIStatus.ON ? 'OFF' : 'ON';
      this.powerSwitchClicked = true;
      // next we retrieve the current power value as a boolean and invert it using ! to get the intended power status we want to switch to
      const powerBoolean = this.vdiPowerStatus === VDIStatus.ON;
      const res = await vdiService.switchVDIPower(this.subscriptionID, this.resourceGroupName, this.vmName, this.userEmail, !powerBoolean);
      this.handleVDISwitch(res, this.vdiPowerText);
    },
    clickPowerButton: function () {
      if (this.vdiPowerStatus === VDIStatus.OFF) {
        this.switchVDI();
      } else {
        this.toggleConfirmation();
      }
    },
    refreshPollingCountdown: async function () {
      this.vdiRefreshCount = 0;
      this.vdiPowerStatus = await vdiService.retrieveVDIPowerStatus(this.subscriptionID, this.resourceGroupName, this.vmName);
      if (this.vdiPowerStatus !== VDIStatus.UNRESPONSIVE) {
        this.powerSwitchClicked = false;
      }
    }
  },
  destroyed () {
    if (this.pollCountdown) {
      clearInterval(this.pollCountdown);
    }
  }
};
