
import { mapGetters } from 'vuex';

export default {
  updated () {
    this.notificationCount = this.notifications.length;
  },
  data () {
    return {
      loggedInUser: "",
      notificationBtn: "btn-primary",
      notificationBadge: "bg-light",
      initialsColour: "light-text",
      newNoticationPresent: false,
      notificationCount: 0,
      showConfirmation: false
    };
  },
  computed: {
    ...mapGetters({
      msalObj: "getMsalObj",
      notifications: "getNotifications"
    }),
    canMarkAsSeen () {
      if (this.notificationCount && this.newNoticationPresent) {
        return true;
      }
      return false;
    }
  },
  created () {
    if (this.msalObj.getActiveAccount()) {
      this.loggedInUser = this.msalObj.getActiveAccount().name;
    }
  },
  methods: {
    clearNotifications () {
      this.$store.dispatch("populateNotifications", { notificationArray: [] });
      this.toggleConfirmation();
      this.notificationCount = 0;
    },
    markAsSeen () {
      this.newNoticationPresent = false;
      this.setNotificationBadge("empty");
    },
    setNotificationBadge (status: string) {
      if (status === "new") {
        this.notificationBtn = "btn-warning";
        this.notificationBadge = "bg-success";
        this.initialsColour = "dark-text";
      } else if (status === "empty") {
        this.notificationBtn = "btn-primary";
        this.notificationBadge = "bg-light";
        this.initialsColour = "light-text";
      }
    },
    toggleConfirmation () {
      this.showConfirmation = !this.showConfirmation;
    }
  },
  watch: {
    notifications: {
      deep: true,
      handler (newNotifications) {
        if (newNotifications.length > this.notificationCount) {
          this.notificationCount = newNotifications.length;
          this.newNoticationPresent = true;
          this.setNotificationBadge("new");
        }
        if (this.notificationCount === 0) {
          this.setNotificationBadge("empty");
        }
      }
    }
  }
};
