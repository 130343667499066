var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.serviceOverview.length)?_c('div',[_c('table',{staticClass:"table"},[_vm._m(0),_vm._l((_vm.serviceTypes),function(serviceType){return _c('tbody',{key:serviceType.label},[_c('tr',[_c('strong',[_vm._v(_vm._s(serviceType.readable))])]),_vm._l((serviceType.services),function(service){return [_c('tr',{key:service.id},[_c('td',[_c('span',{attrs:{"title":'SID:' + service.id}},[_vm._v(_vm._s(service.name))])]),_c('td',[_vm._v(" "+_vm._s(_vm._f("upper")(service.region))+" ")]),_c('td',[_c('router-link',{staticClass:"btn btn-outline-primary",class:service.highlight ? 'highlight' : '',attrs:{"data-cy":"pendingStudies","to":{
                  name: 'studies',
                  params: { service_id: service.id, status: 'pending' },
                }}},[_vm._v(_vm._s(service.pending))])],1),_c('td',[_c('router-link',{staticClass:"btn btn-outline-danger",attrs:{"data-cy":"escalatedStudies","to":{
                  name: 'escalatedStudies',
                  params: { service_id: service.id },
                }}},[_vm._v(_vm._s(service.escalation))])],1),_c('td',[(service.vdi)?_c('div',[(service.vdi.status === 'AVAILABLE')?_c('div',[_vm._v(" Available "),_c('br'),_c('small',[_vm._v(_vm._s(service.vdi.availablity.taken)+" out of "+_vm._s(service.vdi.availablity.total)+" in use")])]):(service.vdi.status === 'UNAVAILABLE')?_c('div',[_vm._v(" Unavailable "),_c('br'),_c('small',[_vm._v(_vm._s(service.vdi.availablity.total)+" in use")])]):_c('div',[_vm._v(" "+_vm._s(service.vdi.ae_title)+" "),_c('br'),_c('small',[_vm._v(_vm._s(service.vdi.host)+" : "+_vm._s(service.vdi.port))])])]):_vm._e(),(!service.vdi)?_c('div',[_c('small',[_vm._v("No available VDI")])]):_vm._e()])])]})],2)})],2),(_vm.errorInAvailableVDIs)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" Failed to load available VDIs. Please check again later. ")]):_vm._e()]):_vm._e(),(!_vm.serviceOverview.length && _vm.apiError.error)?_c('div',{staticClass:"api-error-present"},[_c('h3',[_vm._v(_vm._s(_vm.apiError.message))]),_c('h4',[_vm._v("Output: "+_vm._s(_vm.apiError.error))]),_c('h4',[_vm._v("Check the status of the "+_vm._s(_vm.apiError.service)+" within the Health Status page")])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Service")]),_c('th',[_vm._v("Region")]),_c('th',[_vm._v("Ready to Report")]),_c('th',[_vm._v("On Hold")]),_c('th',[_vm._v("Available VDI?")])])])
}]

export { render, staticRenderFns }