
import Navigation from "@/components/Navigation.vue";
import RDPLogin from "@/components/RDPLogin.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { mapGetters } from "vuex";
import env from "./env.json";

export default {
  components: {
    Navigation,
    RDPLogin,
    LoadingSpinner
  },
  data () {
    return {
      path: "",
      showSupportBox: false,
      inputPlaceholder: "Please enter support info"
    };
  },
  updated () {
    this.$store.commit("setUserName", this.getMsalObj.getActiveAccount().name);
    this.$store.commit("setUserEmail", this.getMsalObj.getActiveAccount().username);
    const notifications = JSON.parse(localStorage.getItem('Notifications'));
    if (Array.isArray(notifications)) {
      this.$store.dispatch("populateNotifications", { notificationArray: notifications });
    }
  },
  methods: {
    toggleShowSupportBox () {
      this.showSupportBox = !this.showSupportBox;
    }
  },
  computed: {
    ...mapGetters(["getFlash", "ready", "getConfig", "getMsalObj", "getIsUserAvailable"]),
    version () {
      return env.VERSION;
    },
    dev_branch () {
      if (Object.prototype.hasOwnProperty.call(env, "DEV_BRANCH")) {
        // eslint-disable-next-line
        return env['DEV_BRANCH'];
      }
      return "";
    },
    isOnStudyListPage: function () {
      if (this.path.includes("/escalation") || this.path.includes("/worklist")) {
        return true;
      }
      return false;
    },
    messageClass () {
      if (this.getFlash.status === "error") {
        return ["alert", "alert-danger"];
      } else if (this.getFlash.status) {
        return ["alert", "alert-success"];
      } else {
        return ["alert", "alert-warning"];
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler (newValue) {
        this.path = newValue.path;
      }
    }
  }
};
